var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "box-with-header",
    { attrs: { fixed: "", "no-header": _vm.disableActions } },
    [
      _vm.revision && !_vm.$route.params.export
        ? _c(
            "div",
            {
              staticClass: "header-box",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", [
                _vm.retriedVerif
                  ? _c("p", { staticClass: "red-block" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("VerificationProcessRestarted")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "buttons-group" },
                [
                  _vm.canUseStoplist && !_vm.isAddedToStoplist
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "margin-right",
                          attrs: {
                            disabled: _vm.isProcessing,
                            small: "",
                            metal: "",
                          },
                          on: { click: _vm.clickAddToStoplist },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("Stoplist.Add")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canUseStoplist && _vm.isAddedToStoplist
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "margin-right",
                          attrs: {
                            disabled: _vm.isProcessing,
                            small: "",
                            metal: "",
                          },
                          on: { click: _vm.clickRemoveFromStoplist },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("Stoplist.Remove")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canModifyVerification
                    ? _c("ui-dropdown", {
                        attrs: {
                          placeholder: _vm.$t("AppReject"),
                          options: _vm.rejectionReasons,
                          disabled: _vm.isProcessing,
                          small: "",
                          danger: "",
                          left: "",
                        },
                        on: { input: _vm.rejectionReasonSelected },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canModifyVerification
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "btn-success",
                          attrs: { disabled: _vm.isProcessing, small: "" },
                          on: { click: _vm.approveIdentification },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("AppApprove")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUnfinished
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "btn-silver",
                          attrs: { disabled: _vm.isDeleting, small: "" },
                          on: { click: _vm.deleteIdentification },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("AppDelete")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ui-dropdown", {
                    staticClass: "margin-right",
                    attrs: {
                      options: _vm.exportOptions,
                      placeholder: "AppExport",
                      small: "",
                      metal: "",
                    },
                    on: { input: _vm.exportList },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ui-container",
        { staticClass: "identification-details-page" },
        [
          _vm.hideButtons
            ? _c(
                "div",
                {
                  staticClass: "loader-absolute",
                  class: { "white-bg": _vm.$route.params.export },
                },
                [_c("ui-loader", { attrs: { size: 4, center: "" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.revision
            ? _c(
                "div",
                {
                  staticClass: "row",
                  class: { "hide-buttons": _vm.hideButtons },
                },
                [
                  _vm.media
                    ? _c(
                        "div",
                        { staticClass: "col-xl-4 col-lg-5 col-md-5" },
                        [
                          _c(
                            "scroll-box",
                            { attrs: { visible: _vm.fullSize } },
                            [
                              _c("media-box", { attrs: { media: _vm.media } }),
                              _vm._v(" "),
                              _vm.kycDetails.videoFileUrl
                                ? _c(
                                    "ui-card",
                                    {
                                      staticClass:
                                        "text-uppercase media-export-block",
                                      attrs: {
                                        title: _vm.$t("DetailsVideoCallTitle"),
                                        icon: "flaticon-user",
                                        dark: "",
                                      },
                                    },
                                    [
                                      _c("video-box", {
                                        attrs: {
                                          src: _vm.kycDetails.videoFileUrl,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.kycDetails.screenRecordUrl
                                ? _c(
                                    "ui-card",
                                    {
                                      staticClass:
                                        "text-uppercase media-export-block",
                                      attrs: {
                                        title: _vm.$t(
                                          "DetailsScreenShareTitle"
                                        ),
                                        icon: "flaticon-user",
                                        dark: "",
                                      },
                                    },
                                    [
                                      _c("video-box", {
                                        attrs: {
                                          src: _vm.kycDetails.screenRecordUrl,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.screenShareVideoFile
                                ? _c(
                                    "ui-card",
                                    {
                                      staticClass:
                                        "text-uppercase media-export-block",
                                      attrs: {
                                        title: _vm.$t(
                                          "DetailsScreenShareTitle"
                                        ),
                                        icon: "flaticon-user",
                                        dark: "",
                                      },
                                    },
                                    [
                                      _c("video-box", {
                                        attrs: {
                                          src: _vm.screenShareVideoFile,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.proofOfAddress
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "proof-of-address-export-block",
                                    },
                                    _vm._l(_vm.proofOfAddress, function (
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          item.fileType === "image"
                                            ? _c(
                                                "ui-card",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "ProofOfAddress"
                                                    ),
                                                    icon: "flaticon-user",
                                                    dark: "",
                                                  },
                                                },
                                                [
                                                  _c("photo-box", {
                                                    attrs: {
                                                      data: item,
                                                      token:
                                                        _vm.identificationId,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.media
                        ? "col-xl-8 col-lg-7 col-md-7"
                        : "col-xl-12",
                    },
                    [
                      _c("scroll-box", [
                        _c(
                          "div",
                          { staticClass: "flex-row" },
                          [
                            _c("document-data", {
                              staticClass: "full-size",
                              class: _vm.media
                                ? "col-xl-6 col-lg-12 col-md-12"
                                : "col-md-6",
                              attrs: {
                                "has-nfc": _vm.hasNFC,
                                "has-qr": _vm.hasQR,
                                data: _vm.documentData,
                                "edit-enabled": _vm.isEditEnabled,
                                disabled: _vm.isLoading,
                                "can-modify": _vm.canModifyVerification,
                                identification: _vm.identificationData,
                              },
                              on: {
                                validation: _vm.validationState,
                                edit: _vm.enableEdit,
                                save: _vm.saveChanges,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "full-size reg-export-block wide-content",
                                class: _vm.media
                                  ? "col-xl-6 col-lg-12 col-md-12"
                                  : "col-md-6",
                              },
                              [
                                _vm.registrationData
                                  ? _c(
                                      "ui-card",
                                      {
                                        attrs: {
                                          title: "DetailsRegistration",
                                          icon: "flaticon-share",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              slot: "body",
                                              id: "document-information",
                                            },
                                            slot: "body",
                                          },
                                          [
                                            _c(
                                              "details-box",
                                              [
                                                _c("details-item", {
                                                  attrs: {
                                                    label: "DetailsStartTime",
                                                    value:
                                                      _vm.registrationData
                                                        .started,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.registrationData.finished
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsFinishTime",
                                                        value:
                                                          _vm.registrationData
                                                            .finished,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.consentAgreedTime
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "AppConsentAgreed",
                                                        value:
                                                          _vm.consentAgreedTime,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.email
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label: "DetailsEmail",
                                                        value:
                                                          _vm.registrationData
                                                            .email,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.phone
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsPhoneNumber",
                                                        value:
                                                          _vm.registrationData
                                                            .phone,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.firstName
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsFirstName",
                                                        value:
                                                          _vm.registrationData
                                                            .firstName,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.middleName
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsMiddleName",
                                                        value:
                                                          _vm.registrationData
                                                            .middleName,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.lastName
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsLastName",
                                                        value:
                                                          _vm.registrationData
                                                            .lastName,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData
                                                  .personalCode
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsPersonCode",
                                                        value:
                                                          _vm.registrationData
                                                            .personalCode,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.dateOfBirth
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsBirthdate",
                                                        value: _vm._f(
                                                          "dateWithoutTime"
                                                        )(
                                                          _vm.registrationData
                                                            .dateOfBirth
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData
                                                  .documentExpirationDate
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsExpireDate",
                                                        value: _vm._f("date")(
                                                          _vm.registrationData
                                                            .documentExpirationDate
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.address
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label: "DetailsAddress",
                                                        value:
                                                          _vm.registrationData
                                                            .address,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData.ipAddress
                                                  ? _c(
                                                      "details-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "DetailsPersonIp",
                                                          value:
                                                            _vm.registrationData
                                                              .ipAddress,
                                                        },
                                                      },
                                                      [
                                                        _vm.relatedIpIdentificationsCount
                                                          ? _c(
                                                              "ui-button",
                                                              {
                                                                staticClass:
                                                                  "btn-metal",
                                                                attrs: {
                                                                  small: "",
                                                                  low: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.showIpListDetails,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        (" +
                                                                    _vm._s(
                                                                      _vm.relatedIpIdentificationsCount
                                                                    ) +
                                                                    ")\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.registrationData
                                                  .ipCountryCode
                                                  ? _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsIpCountryCode",
                                                        value: _vm.$t(
                                                          _vm.registrationData
                                                            .ipCountryCode
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.deviceDetails
                                              ? _c(
                                                  "details-box",
                                                  [
                                                    _vm._l(
                                                      _vm.deviceDetails,
                                                      function (value, key) {
                                                        return [
                                                          value
                                                            ? _c(
                                                                "details-item",
                                                                {
                                                                  key: key,
                                                                  attrs: {
                                                                    label: _vm._f(
                                                                      "deviceLabelProperty"
                                                                    )(key),
                                                                    value: value,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.rawRequestData
                                              ? _c(
                                                  "details-box",
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "AdditionalInfo.Title"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.rawRequestData,
                                                      function (value, key) {
                                                        return [
                                                          value
                                                            ? _c(
                                                                "details-item",
                                                                {
                                                                  key: key,
                                                                  attrs: {
                                                                    label: key,
                                                                    value: value,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.hasDeviceInformation
                                              ? _c(
                                                  "details-box",
                                                  [
                                                    _vm._l(
                                                      _vm.getDeviceInformation,
                                                      function (value, key) {
                                                        return [
                                                          value
                                                            ? _c(
                                                                "details-item",
                                                                {
                                                                  key: key,
                                                                  attrs: {
                                                                    label: _vm._f(
                                                                      "deviceInformationLabelProperty"
                                                                    )(key),
                                                                    value: value,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.identificationData
                              ? _c(
                                  "ui-card",
                                  {
                                    staticClass: "ident-export-block",
                                    attrs: {
                                      title: "DetailsIdentification",
                                      icon: "flaticon-settings-1",
                                      multicolumn: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "body-left" },
                                        slot: "body-left",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-box m-widget1",
                                          },
                                          [
                                            _c("status-item", {
                                              attrs: {
                                                label: "DetailsStatus",
                                                value:
                                                  _vm.identificationData.status,
                                                success: _vm.identificationData.status
                                                  .toLowerCase()
                                                  .includes("passed"),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("status-item", {
                                              attrs: {
                                                label:
                                                  "DetailsCrossCheckStatus",
                                                success:
                                                  _vm.identificationData
                                                    .isCrossChecked,
                                                value: _vm.identificationData
                                                  .isCrossChecked
                                                  ? _vm.$t("CrossChecked")
                                                  : _vm.$t("NotCrossChecked"),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("status-item", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.identificationData
                                                      .rejectionReason,
                                                  expression:
                                                    "identificationData.rejectionReason",
                                                },
                                              ],
                                              attrs: {
                                                label: "DetailsRejectReason",
                                                value:
                                                  _vm.identificationData
                                                    .rejectionReason,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("status-item", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.identificationData
                                                      .companyName,
                                                  expression:
                                                    "identificationData.companyName",
                                                },
                                              ],
                                              attrs: {
                                                label: "DetailsCompanyName",
                                                value:
                                                  _vm.identificationData
                                                    .companyName,
                                                success: true,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.scoresData
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-box m-widget1",
                                              },
                                              [
                                                _c(
                                                  "details-box",
                                                  { staticClass: "rules-box" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "FaceMatchEvaluation"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.scoresData,
                                                      function (item, index) {
                                                        return _c(
                                                          "details-item",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              label:
                                                                "SCORES_" +
                                                                item.name,
                                                              value: item.score,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.nfcScore
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-box m-widget1",
                                              },
                                              [
                                                _c(
                                                  "details-box",
                                                  { staticClass: "rules-box" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "FaceMatchEvaluationNFC"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.nfcScore,
                                                      function (item, index) {
                                                        return _c(
                                                          "details-item",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              label:
                                                                "SCORES_" +
                                                                item.name,
                                                              value: item.score,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.kybDetails
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-box m-widget1",
                                              },
                                              [
                                                _c(
                                                  "details-box",
                                                  { staticClass: "rules-box" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "DetailsKyb.Title"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsKyb.CountryName",
                                                        value:
                                                          _vm.kybDetails
                                                            .country,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsKyb.Name",
                                                        value:
                                                          _vm.kybDetails.name,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "DetailsKyb.Code",
                                                        value:
                                                          _vm.kybDetails.code,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    typeof _vm.kybDetails
                                                      .isSuccess === "boolean"
                                                      ? _c("status-item", {
                                                          attrs: {
                                                            label:
                                                              "DetailsKyb.Representation",
                                                            value: _vm
                                                              .kybDetails
                                                              .isSuccess
                                                              ? "DetailsKyb.Success"
                                                              : "DetailsKyb.Fail",
                                                            success:
                                                              _vm.kybDetails
                                                                .isSuccess,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.signDetails
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-box m-widget1",
                                              },
                                              [
                                                _c(
                                                  "details-box",
                                                  { staticClass: "rules-box" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "SignDetails.Title"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "SignDetails.Hash",
                                                        value:
                                                          _vm.signDetails.hash,
                                                        hidden: "",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("details-item", {
                                                      attrs: {
                                                        label:
                                                          "SignDetails.Time",
                                                        value:
                                                          _vm.signDetails.time,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("status-item", {
                                                      attrs: {
                                                        label:
                                                          "SignDetails.Signed",
                                                        success:
                                                          _vm.signDetails
                                                            .signed,
                                                        value: _vm.signDetails
                                                          .signed
                                                          ? "SignDetails.Yes"
                                                          : "SignDetails.No",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.passedManualOrAuto(
                                          _vm.revision.registrationData.status
                                        ) && _vm.hasMonitoring
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-box m-widget1",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center justify-content-between",
                                                  },
                                                  [
                                                    !_vm.getMonitoringStatus(
                                                      _vm.revision
                                                        .monitoringData
                                                        .monitoringStatus
                                                    )
                                                      ? _c("h5", [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "AddMonitoring"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ])
                                                      : _c("h5", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "RemoveMonitoring"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                    _vm._v(" "),
                                                    _c("ui-toggle", {
                                                      attrs: {
                                                        prevent: "",
                                                        value: _vm.getMonitoringStatus(
                                                          _vm.revision
                                                            .monitoringData
                                                            .monitoringStatus
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeMonitoring(
                                                            _vm.revision
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.identificationData.videoCallComments
                                          .length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-box m-widget1",
                                              },
                                              [
                                                _c("comments", {
                                                  attrs: {
                                                    comments:
                                                      _vm.identificationData
                                                        .videoCallComments,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "body-right" },
                                        slot: "body-right",
                                      },
                                      [
                                        _vm.validationChecksRules
                                          ? _c("rule-list", {
                                              staticClass: "m-widget1",
                                              attrs: {
                                                title:
                                                  "DetailsValidationChecks",
                                                type: "rules",
                                                data: _vm.validationChecksRules,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.identificationData.registries
                                          ? _c("rule-list", {
                                              staticClass: "m-widget1",
                                              attrs: {
                                                "no-status": "",
                                                title: "DetailsRegistryChecks",
                                                "add-button":
                                                  !_vm.disableActions &&
                                                  (_vm.addRefreshButton(
                                                    _vm.identificationData
                                                      .registries
                                                  ) ||
                                                    _vm.addReprocessButton),
                                                data:
                                                  _vm.identificationData
                                                    .registries,
                                              },
                                              on: {
                                                reprocess:
                                                  _vm.reprocessRegistries,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.basicChecksRules
                                          ? _c("rule-list", {
                                              staticClass: "m-widget1",
                                              attrs: {
                                                title: "DetailsBasicChecks",
                                                type: "rules",
                                                data: _vm.basicChecksRules,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.identificationData.sanctions
                                          ? _c("rule-list", {
                                              staticClass: "m-widget1",
                                              attrs: {
                                                title: "DetailsSanctions",
                                                data:
                                                  _vm.identificationData
                                                    .sanctions,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.fraudChecks
                                          ? _c("rule-list", {
                                              staticClass: "m-widget1",
                                              attrs: {
                                                title:
                                                  "Identification.Rules.Label.Fraud",
                                                data: _vm.fraudChecks,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.formData
                              ? _c(
                                  "div",
                                  { staticClass: "form-data" },
                                  [
                                    _c(
                                      "ui-card",
                                      {
                                        attrs: {
                                          title:
                                            "DetailsRepresentativeQuestionaire",
                                          icon: "flaticon-share",
                                        },
                                      },
                                      [
                                        _c("iframe", {
                                          ref: "form",
                                          attrs: { src: _vm.formSource },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.proofOfAddressData != null
                              ? _c("POA-data", {
                                  attrs: {
                                    data: _vm.proofOfAddressData,
                                    source: _vm.proofOfAddressSource,
                                    "edit-enabled": _vm.isEditPOAEnabled,
                                    disabled: _vm.isLoading,
                                    "can-modify": _vm.canModifyVerification,
                                  },
                                  on: {
                                    validation: _vm.validationState,
                                    edit: _vm.enablePOAEdit,
                                    save: _vm.savePOAChanges,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.logData
                              ? _c(
                                  "ui-card",
                                  {
                                    staticClass: "logs-export-block",
                                    attrs: {
                                      title: "DetailsUserLog",
                                      icon: "flaticon-more-v5",
                                    },
                                    on: { click: _vm.showHideLogs },
                                  },
                                  [
                                    _c("div", {
                                      class: !_vm.hideLogs
                                        ? "hide-button"
                                        : "show-button",
                                      attrs: { slot: "tools" },
                                      slot: "tools",
                                    }),
                                    _vm._v(" "),
                                    !_vm.hideLogs
                                      ? _c(
                                          "template",
                                          { slot: "body" },
                                          [
                                            _vm.kycLogsUi
                                              ? _c(
                                                  "ui-widget",
                                                  {
                                                    attrs: {
                                                      title: "DetailsKycLogs",
                                                    },
                                                  },
                                                  [
                                                    _c("log-list", {
                                                      attrs: {
                                                        items: _vm.kycLogsUi,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.boLogsUi
                                              ? _c(
                                                  "ui-widget",
                                                  {
                                                    attrs: {
                                                      title: "DetailsBoLogs",
                                                    },
                                                  },
                                                  [
                                                    _c("log-list", {
                                                      attrs: {
                                                        items: _vm.boLogsUi,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm.isReady
            ? _c("span", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("DetailsIdentificationNotFound")) +
                    "\n    "
                ),
              ])
            : _c("ui-loader", { attrs: { size: 4, center: "" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        { attrs: { "full-size": "", "overflow-unset": _vm.setOverflowUnset } },
        [
          _vm.popUpType === _vm.KYC_IDENTIFICATION_POP_UP_TYPES.IP_LIST
            ? [
                _c("h4", [_vm._v(_vm._s(_vm.$t("DetailsIpIdentPopupTitle")))]),
                _vm._v(" "),
                _c(
                  "ol",
                  _vm._l(_vm.relatedIpIdentifications, function (ipIdent) {
                    return _c(
                      "li",
                      { key: ipIdent.identificationId },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "IdentificationDetails",
                                params: {
                                  identificationId: ipIdent.identificationId,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(ipIdent.identificationId) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.identificationId === ipIdent.identificationId
                          ? [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.$t("DetailsIpIdentPopupCurrent")) +
                                  ")\n          "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            : _vm.popUpType === _vm.KYC_IDENTIFICATION_POP_UP_TYPES.SEON_EMAIL
            ? _c("seon-email", { attrs: { "file-id": _vm.seonFileId } })
            : _vm.popUpType === _vm.KYC_IDENTIFICATION_POP_UP_TYPES.SEON_IP
            ? _c("seon-ip", { attrs: { "file-id": _vm.seonFileId } })
            : _vm.popUpType ===
              _vm.KYC_IDENTIFICATION_POP_UP_TYPES.STOPLIST_CONFIRM
            ? [
                _c("div", [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("DetailsPleaseConfirm")))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "popup-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("Stoplist.PopupConfirmMessage")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "buttons-group" },
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "btn-success",
                          attrs: { small: "" },
                          on: { click: _vm.confirmAddToStoplist },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("AppConfirm")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ui-button",
                        {
                          attrs: { metal: "", small: "" },
                          on: { click: _vm.abortPopUp },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("AppCancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm.popUpType ===
              _vm.KYC_IDENTIFICATION_POP_UP_TYPES.MONITORING_SETUP
            ? [
                _c("monitoring-setup", {
                  attrs: { data: _vm.monitoringData, "is-single": "" },
                  on: { requestStatus: _vm.handleStatus },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }