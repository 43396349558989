<template>
  <box-with-header fixed :no-header="disableActions">
    <div
      v-if="revision && !$route.params.export"
      slot="header"
      class="header-box"
    >
      <div>
        <p v-if="retriedVerif" class="red-block">
          {{ $t('VerificationProcessRestarted') }}
        </p>
      </div>
      <div class="buttons-group">
        <ui-button
          v-if="canUseStoplist && !isAddedToStoplist"
          :disabled="isProcessing"
          class="margin-right"
          small
          metal
          @click="clickAddToStoplist"
        >
          {{ $t('Stoplist.Add') }}
        </ui-button>
        <ui-button
          v-if="canUseStoplist && isAddedToStoplist"
          :disabled="isProcessing"
          class="margin-right"
          small
          metal
          @click="clickRemoveFromStoplist"
        >
          {{ $t('Stoplist.Remove') }}
        </ui-button>
        <ui-dropdown
          v-if="canModifyVerification"
          :placeholder="$t('AppReject')"
          :options="rejectionReasons"
          :disabled="isProcessing"
          small
          danger
          left
          @input="rejectionReasonSelected"
        />
        <ui-button
          v-if="canModifyVerification"
          class="btn-success"
          :disabled="isProcessing"
          small
          @click="approveIdentification"
        >
          {{ $t('AppApprove') }}
        </ui-button>
        <ui-button
          v-if="isUnfinished"
          class="btn-silver"
          :disabled="isDeleting"
          small
          @click="deleteIdentification"
        >
          {{ $t('AppDelete') }}
        </ui-button>
        <ui-dropdown
          :options="exportOptions"
          placeholder="AppExport"
          class="margin-right"
          small
          metal
          @input="exportList"
        />
      </div>
    </div>
    <ui-container class="identification-details-page">
      <div
        v-if="hideButtons"
        class="loader-absolute"
        :class="{ 'white-bg': $route.params.export }"
      >
        <ui-loader :size="4" center />
      </div>
      <div v-if="revision" class="row" :class="{ 'hide-buttons': hideButtons }">
        <div v-if="media" class="col-xl-4 col-lg-5 col-md-5">
          <scroll-box :visible="fullSize">
            <media-box :media="media" />
            <ui-card
              v-if="kycDetails.videoFileUrl"
              :title="$t('DetailsVideoCallTitle')"
              class="text-uppercase media-export-block"
              icon="flaticon-user"
              dark
            >
              <video-box :src="kycDetails.videoFileUrl" />
            </ui-card>
            <ui-card
              v-if="kycDetails.screenRecordUrl"
              :title="$t('DetailsScreenShareTitle')"
              class="text-uppercase media-export-block"
              icon="flaticon-user"
              dark
            >
              <video-box :src="kycDetails.screenRecordUrl" />
            </ui-card>
            <ui-card
              v-if="screenShareVideoFile"
              :title="$t('DetailsScreenShareTitle')"
              class="text-uppercase media-export-block"
              icon="flaticon-user"
              dark
            >
              <video-box :src="screenShareVideoFile" />
            </ui-card>
            <div v-if="proofOfAddress" class="proof-of-address-export-block">
              <div v-for="(item, index) in proofOfAddress" :key="index">
                <ui-card
                  v-if="item.fileType === 'image'"
                  :title="$t('ProofOfAddress')"
                  class="text-uppercase"
                  icon="flaticon-user"
                  dark
                >
                  <photo-box :data="item" :token="identificationId" />
                </ui-card>
              </div>
            </div>
          </scroll-box>
        </div>
        <div :class="media ? 'col-xl-8 col-lg-7 col-md-7' : 'col-xl-12'">
          <scroll-box>
            <div class="flex-row">
              <document-data
                class="full-size"
                :class="media ? 'col-xl-6 col-lg-12 col-md-12' : 'col-md-6'"
                :has-nfc="hasNFC"
                :has-qr="hasQR"
                :data="documentData"
                :edit-enabled="isEditEnabled"
                :disabled="isLoading"
                :can-modify="canModifyVerification"
                :identification="identificationData"
                @validation="validationState"
                @edit="enableEdit"
                @save="saveChanges"
              />
              <div
                class="full-size reg-export-block wide-content"
                :class="media ? 'col-xl-6 col-lg-12 col-md-12' : 'col-md-6'"
              >
                <ui-card
                  v-if="registrationData"
                  title="DetailsRegistration"
                  icon="flaticon-share"
                >
                  <div id="document-information" slot="body">
                    <details-box>
                      <details-item
                        label="DetailsStartTime"
                        :value="registrationData.started"
                      />
                      <details-item
                        v-if="registrationData.finished"
                        label="DetailsFinishTime"
                        :value="registrationData.finished"
                      />
                      <details-item
                        v-if="consentAgreedTime"
                        label="AppConsentAgreed"
                        :value="consentAgreedTime"
                      />
                      <details-item
                        v-if="registrationData.email"
                        label="DetailsEmail"
                        :value="registrationData.email"
                      />
                      <details-item
                        v-if="registrationData.phone"
                        label="DetailsPhoneNumber"
                        :value="registrationData.phone"
                      />
                      <details-item
                        v-if="registrationData.firstName"
                        label="DetailsFirstName"
                        :value="registrationData.firstName"
                      />
                      <details-item
                        v-if="registrationData.middleName"
                        label="DetailsMiddleName"
                        :value="registrationData.middleName"
                      />
                      <details-item
                        v-if="registrationData.lastName"
                        label="DetailsLastName"
                        :value="registrationData.lastName"
                      />
                      <details-item
                        v-if="registrationData.personalCode"
                        label="DetailsPersonCode"
                        :value="registrationData.personalCode"
                      />
                      <details-item
                        v-if="registrationData.dateOfBirth"
                        label="DetailsBirthdate"
                        :value="registrationData.dateOfBirth | dateWithoutTime"
                      />
                      <details-item
                        v-if="registrationData.documentExpirationDate"
                        label="DetailsExpireDate"
                        :value="registrationData.documentExpirationDate | date"
                      />
                      <details-item
                        v-if="registrationData.address"
                        label="DetailsAddress"
                        :value="registrationData.address"
                      />
                      <details-item
                        v-if="registrationData.ipAddress"
                        label="DetailsPersonIp"
                        :value="registrationData.ipAddress"
                      >
                        <ui-button
                          v-if="relatedIpIdentificationsCount"
                          small
                          low
                          class="btn-metal"
                          @click="showIpListDetails"
                        >
                          ({{ relatedIpIdentificationsCount }})
                        </ui-button>
                      </details-item>
                      <details-item
                        v-if="registrationData.ipCountryCode"
                        label="DetailsIpCountryCode"
                        :value="$t(registrationData.ipCountryCode)"
                      />
                    </details-box>
                    <details-box v-if="deviceDetails">
                      <template v-for="(value, key) in deviceDetails">
                        <details-item
                          v-if="value"
                          :key="key"
                          :label="key | deviceLabelProperty"
                          :value="value"
                        />
                      </template>
                    </details-box>
                    <details-box v-if="rawRequestData">
                      <h5>{{ $t('AdditionalInfo.Title') }}</h5>
                      <template v-for="(value, key) in rawRequestData">
                        <details-item
                          v-if="value"
                          :key="key"
                          :label="key"
                          :value="value"
                        />
                      </template>
                    </details-box>
                    <details-box v-if="hasDeviceInformation">
                      <template v-for="(value, key) in getDeviceInformation">
                        <details-item
                          v-if="value"
                          :key="key"
                          :label="key | deviceInformationLabelProperty"
                          :value="value"
                        />
                      </template>
                    </details-box>
                  </div>
                </ui-card>
              </div>
            </div>
            <div>
              <ui-card
                v-if="identificationData"
                title="DetailsIdentification"
                class="ident-export-block"
                icon="flaticon-settings-1"
                multicolumn
              >
                <div slot="body-left">
                  <div class="content-box m-widget1">
                    <status-item
                      label="DetailsStatus"
                      :value="identificationData.status"
                      :success="
                        identificationData.status
                          .toLowerCase()
                          .includes('passed')
                      "
                    />
                    <status-item
                      label="DetailsCrossCheckStatus"
                      :success="identificationData.isCrossChecked"
                      :value="
                        identificationData.isCrossChecked
                          ? $t('CrossChecked')
                          : $t('NotCrossChecked')
                      "
                    />
                    <status-item
                      v-show="identificationData.rejectionReason"
                      label="DetailsRejectReason"
                      :value="identificationData.rejectionReason"
                    />
                    <status-item
                      v-show="identificationData.companyName"
                      label="DetailsCompanyName"
                      :value="identificationData.companyName"
                      :success="true"
                    />
                  </div>
                  <div v-if="scoresData" class="content-box m-widget1">
                    <details-box class="rules-box">
                      <h5>{{ $t('FaceMatchEvaluation') }}</h5>
                      <details-item
                        v-for="(item, index) in scoresData"
                        :key="index"
                        :label="`SCORES_${item.name}`"
                        :value="item.score"
                      />
                    </details-box>
                  </div>
                  <div v-if="nfcScore" class="content-box m-widget1">
                    <details-box class="rules-box">
                      <h5>{{ $t('FaceMatchEvaluationNFC') }}</h5>
                      <details-item
                        v-for="(item, index) in nfcScore"
                        :key="index"
                        :label="`SCORES_${item.name}`"
                        :value="item.score"
                      />
                    </details-box>
                  </div>
                  <div v-if="kybDetails" class="content-box m-widget1">
                    <details-box class="rules-box">
                      <h5>{{ $t('DetailsKyb.Title') }}</h5>
                      <details-item
                        label="DetailsKyb.CountryName"
                        :value="kybDetails.country"
                      />
                      <details-item
                        label="DetailsKyb.Name"
                        :value="kybDetails.name"
                      />
                      <details-item
                        label="DetailsKyb.Code"
                        :value="kybDetails.code"
                      />
                      <status-item
                        v-if="typeof kybDetails.isSuccess === 'boolean'"
                        label="DetailsKyb.Representation"
                        :value="
                          kybDetails.isSuccess
                            ? 'DetailsKyb.Success'
                            : 'DetailsKyb.Fail'
                        "
                        :success="kybDetails.isSuccess"
                      />
                    </details-box>
                  </div>
                  <div v-if="signDetails" class="content-box m-widget1">
                    <details-box class="rules-box">
                      <h5>{{ $t('SignDetails.Title') }}</h5>
                      <details-item
                        label="SignDetails.Hash"
                        :value="signDetails.hash"
                        hidden
                      />
                      <details-item
                        label="SignDetails.Time"
                        :value="signDetails.time"
                      />
                      <status-item
                        label="SignDetails.Signed"
                        :success="signDetails.signed"
                        :value="
                          signDetails.signed
                            ? 'SignDetails.Yes'
                            : 'SignDetails.No'
                        "
                      />
                    </details-box>
                  </div>
                  <div
                    v-if="
                      passedManualOrAuto(revision.registrationData.status) &&
                      hasMonitoring
                    "
                    class="content-box m-widget1"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <h5
                        v-if="
                          !getMonitoringStatus(
                            revision.monitoringData.monitoringStatus
                          )
                        "
                      >
                        {{ $t('AddMonitoring') }}
                      </h5>
                      <h5 v-else>{{ $t('RemoveMonitoring') }}</h5>
                      <ui-toggle
                        prevent
                        :value="
                          getMonitoringStatus(
                            revision.monitoringData.monitoringStatus
                          )
                        "
                        @change="changeMonitoring(revision)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="identificationData.videoCallComments.length > 0"
                    class="content-box m-widget1"
                  >
                    <comments
                      :comments="identificationData.videoCallComments"
                    />
                  </div>
                </div>
                <div slot="body-right">
                  <rule-list
                    v-if="validationChecksRules"
                    class="m-widget1"
                    title="DetailsValidationChecks"
                    type="rules"
                    :data="validationChecksRules"
                  />
                  <rule-list
                    v-if="identificationData.registries"
                    no-status
                    class="m-widget1"
                    title="DetailsRegistryChecks"
                    :add-button="
                      !disableActions &&
                      (addRefreshButton(identificationData.registries) ||
                        addReprocessButton)
                    "
                    :data="identificationData.registries"
                    @reprocess="reprocessRegistries"
                  />
                  <rule-list
                    v-if="basicChecksRules"
                    class="m-widget1"
                    title="DetailsBasicChecks"
                    type="rules"
                    :data="basicChecksRules"
                  />
                  <rule-list
                    v-if="identificationData.sanctions"
                    class="m-widget1"
                    title="DetailsSanctions"
                    :data="identificationData.sanctions"
                  />
                  <rule-list
                    v-if="fraudChecks"
                    class="m-widget1"
                    title="Identification.Rules.Label.Fraud"
                    :data="fraudChecks"
                  />
                </div>
              </ui-card>
              <div v-if="formData" class="form-data">
                <ui-card
                  title="DetailsRepresentativeQuestionaire"
                  icon="flaticon-share"
                >
                  <iframe ref="form" :src="formSource" />
                </ui-card>
              </div>

              <POA-data
                v-if="proofOfAddressData != null"
                :data="proofOfAddressData"
                :source="proofOfAddressSource"
                :edit-enabled="isEditPOAEnabled"
                :disabled="isLoading"
                :can-modify="canModifyVerification"
                @validation="validationState"
                @edit="enablePOAEdit"
                @save="savePOAChanges"
              />

              <ui-card
                v-if="logData"
                title="DetailsUserLog"
                class="logs-export-block"
                icon="flaticon-more-v5"
                @click="showHideLogs"
              >
                <div
                  slot="tools"
                  :class="!hideLogs ? 'hide-button' : 'show-button'"
                />
                <template v-if="!hideLogs" slot="body">
                  <ui-widget v-if="kycLogsUi" title="DetailsKycLogs">
                    <log-list :items="kycLogsUi" />
                  </ui-widget>
                  <ui-widget v-if="boLogsUi" title="DetailsBoLogs">
                    <log-list :items="boLogsUi" />
                  </ui-widget>
                </template>
              </ui-card>
            </div>
          </scroll-box>
        </div>
      </div>
      <span v-else-if="isReady">
        {{ $t('DetailsIdentificationNotFound') }}
      </span>
      <ui-loader v-else :size="4" center />
    </ui-container>
    <pop-up full-size :overflow-unset="setOverflowUnset">
      <template v-if="popUpType === KYC_IDENTIFICATION_POP_UP_TYPES.IP_LIST">
        <h4>{{ $t('DetailsIpIdentPopupTitle') }}</h4>
        <ol>
          <li
            v-for="ipIdent in relatedIpIdentifications"
            :key="ipIdent.identificationId"
          >
            <router-link
              :to="{
                name: 'IdentificationDetails',
                params: {
                  identificationId: ipIdent.identificationId
                }
              }"
              target="_blank"
            >
              {{ ipIdent.identificationId }}
            </router-link>
            <template v-if="identificationId === ipIdent.identificationId">
              ({{ $t('DetailsIpIdentPopupCurrent') }})
            </template>
          </li>
        </ol>
      </template>
      <seon-email
        v-else-if="popUpType === KYC_IDENTIFICATION_POP_UP_TYPES.SEON_EMAIL"
        :file-id="seonFileId"
      />
      <seon-ip
        v-else-if="popUpType === KYC_IDENTIFICATION_POP_UP_TYPES.SEON_IP"
        :file-id="seonFileId"
      />
      <template
        v-else-if="
          popUpType === KYC_IDENTIFICATION_POP_UP_TYPES.STOPLIST_CONFIRM
        "
      >
        <div>
          <h4>{{ $t('DetailsPleaseConfirm') }}</h4>
          <div class="popup-text">
            {{ $t('Stoplist.PopupConfirmMessage') }}
          </div>
          <div class="buttons-group">
            <ui-button class="btn-success" small @click="confirmAddToStoplist">
              {{ $t('AppConfirm') }}
            </ui-button>
            <ui-button metal small @click="abortPopUp">
              {{ $t('AppCancel') }}
            </ui-button>
          </div>
        </div>
      </template>
      <template
        v-else-if="
          popUpType === KYC_IDENTIFICATION_POP_UP_TYPES.MONITORING_SETUP
        "
      >
        <monitoring-setup
          :data="monitoringData"
          is-single
          @requestStatus="handleStatus"
        />
      </template>
    </pop-up>
  </box-with-header>
</template>

<script>
import PhotoBox from '@src/components/partials/PhotoBox'
import VideoBox from '@src/components/partials/VideoBox'
import StatusItem from '@src/components/partials/StatusItem'
import RuleList from '@src/components/partials/RuleList'
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import BoxWithHeader from '@src/components/partials/BoxWithHeader'
import LogList from '@src/components/partials/LogList'
import ScrollBox from '@src/components/partials/ScrollBox'
import SeonEmail from '@src/components/partials/seon/Email'
import SeonIp from '@src/components/partials/seon/Ip'
import DocumentData from '@src/components/partials/Identifications/DocumentData'
import POAData from '@src/components/partials/Identifications/POAData'
import MonitoringSetup from '@src/components/partials/MonitoringSetup'
import Api from '@src/scripts/api'
import { notificationType } from '@src/components/notification'
import { PDFDocument } from 'pdf-lib'
import Comments from '@src/components/partials/comments/Comments'

import {
  IGNORED_ACTION_LOGS,
  EXPORT_NAMES,
  VALID_APP_ID_FOR_VIDEO_EXPORT,
  BASIC_RULES,
  VALIDATION_RULES,
  KYC_IDENTIFICATION_POP_UP_TYPES,
  FORM_TYPES
} from '@src/scripts/constants'
import {
  dateToString,
  mapOptions,
  downloadBlob,
  downloadUrl,
  deepClone,
  toPascalCase,
  dateWithoutTime
} from '@src/scripts/helpers'
import {
  statusTypesEnum,
  exportMethodTypes,
  pageTypeEnum,
  PRODUCTS,
  REQUEST_STATUS,
  MONITORING_CHANGE_STATUS,
  API
} from '@src/scripts/enums'
import actions from '@src/store/actions'
import EventController, { eventTypes } from '@src/scripts/eventController'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'
import MediaBox from '@src/components/pages/identifications/kyc/MediaBox'
import Store from '@src/store'
import { exceptionReasons, exceptionRules } from '@src/scripts/constants'

export default {
  components: {
    MediaBox,
    PhotoBox,
    VideoBox,
    StatusItem,
    RuleList,
    DetailsBox,
    DetailsItem,
    BoxWithHeader,
    LogList,
    ScrollBox,
    DocumentData,
    POAData,
    SeonEmail,
    SeonIp,
    MonitoringSetup,
    Comments
  },

  filters: {
    date: dateToString,
    dateWithoutTime,

    deviceInformationLabelProperty(value) {
      if (!value) return

      const key = toPascalCase(value)
      return `DeviceInformation.${key}`
    },

    deviceLabelProperty(value) {
      if (!value) return

      const key = toPascalCase(value)
      return `DeviceDetails.${key}`
    }
  },

  props: {
    id: { type: String, default: null },
    disableActions: Boolean,
    fullSize: Boolean
  },

  data() {
    return {
      KYC_IDENTIFICATION_POP_UP_TYPES,
      isReady: false,
      isLoading: false,
      isProcessing: false,
      isEditEnabled: false,
      isEditPOAEnabled: false,
      isValid: false,
      revision: null,
      errors: {},
      hideButtons: false,
      time: 60,
      hideLogs: false,
      formEvents: null,
      isFormReady: false,
      cloneDocumentData: null,
      addReprocessButton: false,
      isDeleting: false,
      popUpType: null,
      seonFileId: null,
      screenShareVideoFile: null,
      nfcScore: null,
      monitoringData: {
        identificationToken: null,
        monitoringStatusSelected: null,
        selectedMonitoringIndex: null
      }
    }
  },

  computed: {
    hasNFC() {
      if (this.identificationData.rules) {
        const hasNfcRules = this.identificationData.rules?.filter(
          ({ name }) =>
            name === 'SELFIE_TO_NFC_MATCH' ||
            name === 'SELFIE_NFC_NOT_MATCH' ||
            name === 'DOCUMENT_HAS_NFC'
        )
        return !!hasNfcRules.length
      }

      return false
    },

    hasQR() {
      if (this.identificationData.rules) {
        return this.identificationData.rules?.some(
          ({ name, status }) =>
            name === 'DOCUMENT_HAS_QR' && status === 'SUCCESS'
        )
      }

      return false
    },

    setOverflowUnset() {
      return this.popUpType === KYC_IDENTIFICATION_POP_UP_TYPES.MONITORING_SETUP
    },

    ...mapGetters([getters.user, getters.selectedCompany, getters.companies]),

    userPages() {
      const selectedCompanyId =
        this.selectedCompany || this.user.defaultCompanyId
      const company = this.companies.find(
        (item) => item.id === selectedCompanyId
      )
      return company && company.security && company.security.pages
    },
    isAddedToStoplist() {
      return this.isInStopList(this.identificationData.token)
    },
    canUseStoplist() {
      const { userPages } = this
      if (!userPages) return null
      return userPages.includes(pageTypeEnum.stopList)
    },
    isUnfinished() {
      const { identificationData } = this
      return identificationData?.status?.toUpperCase() === 'UNDEFINED'
    },

    hasMonitoring() {
      const { selectedCompany } = Store.getters
      const currentCompany = this.$store.state.settings.companies.find(
        (company) => selectedCompany === company.id
      )

      return currentCompany.security.products.includes(
        PRODUCTS.NATURAL_ENTITY_MONITORING
      )
    },

    retriedVerif() {
      const { revision: { kycDetails: { actionLogsData } = {} } = {} } = this
      let sessionStartOver = false
      if (actionLogsData)
        actionLogsData.forEach((item) => {
          if (item.name === 'SESSION_START_OVER') sessionStartOver = true
        })
      return sessionStartOver
    },

    relatedIpIdentifications() {
      return this.revision?.kycDetails?.relatedIpIdentifications
    },

    relatedIpIdentificationsCount() {
      return this.relatedIpIdentifications?.length
    },

    consentAgreedTime() {
      const { kycDetails } = this.revision || {}
      if (!kycDetails || !kycDetails.actionLogsData) return '-'

      const agreed = kycDetails.actionLogsData.find(
        (item) => item.name === 'TERMS_AGREED'
      )
      return (agreed && agreed.date && dateToString(agreed.date, true)) || '-'
    },

    canModifyVerification() {
      const { disableActions } = this
      if (disableActions) return false

      const { isCrossChecked } = this.identificationData
      const {
        canModifyVerification,
        canOverrideIdentification
      } = this.$store.getters
      if (this.identificationData.status === 'UNDEFINED') return false
      else if (
        canModifyVerification &&
        !canOverrideIdentification &&
        !isCrossChecked
      ) {
        return true
      } else if (
        canModifyVerification &&
        !canOverrideIdentification &&
        isCrossChecked
      ) {
        return false
      } else if (
        canModifyVerification &&
        canOverrideIdentification &&
        !isCrossChecked
      ) {
        return true
      } else if (
        canModifyVerification &&
        canOverrideIdentification &&
        isCrossChecked
      ) {
        return true
      } else return false
    },

    exportOptions() {
      const {
        revision: {
          kycDetails: { esignatureFileUrl, videoFileUrl, screenRecordUrl } = {},
          exportMethods
        } = {},
        signDetails
      } = this

      const isValidExportVideo = this.canExportVideo()
      const isValidDownloadReport = this.canDownloadReport()
      const array = ['PDF']
      if (esignatureFileUrl) array.push('ASICE')
      if (videoFileUrl) array.push('VIDEO')
      if (screenRecordUrl) array.push('SESSION RECORD')
      if (isValidExportVideo) array.push('SCREEN RECORD')
      if (isValidDownloadReport) array.push('FORM REPORT')

      if (signDetails) {
        if (signDetails.originalDocument) array.push('ORIGINAL DOCUMENT')
        if (signDetails.signedDocument) array.push('SIGNED DOCUMENT')
      }

      if (exportMethods?.includes(exportMethodTypes.thirdPartyExport)) {
        array.push(EXPORT_NAMES[exportMethodTypes.thirdPartyExport])
      }

      return mapOptions(array)
    },

    validationChecksRules() {
      return (
        this.identificationData?.rules?.filter((item) =>
          VALIDATION_RULES.includes(item.name)
        ) || null
      )
    },

    basicChecksRules() {
      return (
        this.identificationData?.rules?.filter((item) =>
          BASIC_RULES.includes(item.name)
        ) || null
      )
    },

    fraudChecks() {
      const { fraudCheckResults } = this.identificationData ?? {}
      if (!fraudCheckResults) {
        return null
      }

      const list = fraudCheckResults.map((item) => ({
        name: item.externalFraudCheckProvider,
        status: this.mapFraudCheckStatus(item.fraudCheckStatus),
        fileId: item.fileId,
        failReasons:
          item.externalFraudCheckProvider === 'BIO_STOP_LIST'
            ? null
            : item.fraudCheckFailReasons,
        action:
          item.externalFraudCheckProvider === 'BIO_STOP_LIST' &&
          item.fraudCheckStatus === 'FAIL' &&
          item.linkedIdentificationId !== undefined
            ? () => {
                window.open(
                  `/app/identifications/kyc/details/${item.linkedIdentificationId.replaceAll(
                    '-',
                    ''
                  )}`,
                  '_blank',
                  'noreferrer'
                )
              }
            : undefined
      }))

      if (!list.length) {
        return null
      }

      const seonEmail = list.find((item) => item.name === 'SEON_EMAIL')
      if (seonEmail?.fileId) {
        seonEmail.action = () =>
          this.showSeonDetails(
            KYC_IDENTIFICATION_POP_UP_TYPES.SEON_EMAIL,
            seonEmail.fileId
          )
      }

      const seonIp = list.find((item) => item.name === 'SEON_IP')
      if (seonIp?.fileId) {
        seonIp.action = () =>
          this.showSeonDetails(
            KYC_IDENTIFICATION_POP_UP_TYPES.SEON_IP,
            seonIp.fileId
          )
      }

      return list
    },

    identificationId() {
      return this.$route.params.identificationId ?? this.id
    },

    rejectionReasons() {
      const { rejectionReasons } = this.$store.getters
      return mapOptions(rejectionReasons)
    },

    media() {
      const { revision } = this
      if (!revision) return null
      const { media } = revision

      const filteredMedia = (media || []).filter(
        (item) => item.givenDocumentType !== 'PROOF_OF_ADDRESS'
      )

      return filteredMedia.length > 0 ? filteredMedia : null
    },

    kycDetails() {
      const { revision: { kycDetails = {} } = {} } = this

      return kycDetails
    },

    proofOfAddress() {
      const {
        revision: { kycDetails: { proofOfAddressFileList } = {} } = {}
      } = this
      if (!proofOfAddressFileList) return null
      const array = []
      proofOfAddressFileList.forEach((item) => {
        if (item.includes('pdf')) array.push({ fileType: 'pdf', file: item })
        else array.push({ fileType: 'image', file: item })
      })
      return array
    },

    proofOfAddressData() {
      const data = this.revision?.proofOfAddress
      if (!data) {
        return null
      }

      return {
        firstName: data.firstName.value,
        lastName: data.lastName.value,
        street: data.street.value,
        city: data.city.value,
        parish: data.parish.value
      }
    },

    proofOfAddressSource() {
      const media = this.revision?.media
      if (!media) {
        return null
      }

      const files = media
        .filter(
          (mediaItem) => mediaItem.givenDocumentType === 'PROOF_OF_ADDRESS'
        )
        .sort((a, b) =>
          this.orderByDateDesc({ date: a.created }, { date: b.created })
        )

      return files.length > 0 ? files[0] : null
    },

    documentData() {
      const documentData = this.revision?.documentDataFlaggedNew
      return {
        ...documentData,
        documentType: this.revision.documentType
      }
    },

    registrationData() {
      const { revision } = this
      if (!revision || !revision.registrationData) return null
      const {
        registrationData: {
          status,
          token,
          isCrossChecked,
          rejectionReason,
          started,
          finished,
          ipAddress,
          ipCountryCode,
          ...details
        }
      } = revision
      return {
        ...details,
        ipAddress,
        ipCountryCode,
        started: dateToString(started, true),
        finished: dateToString(finished, true)
      }
    },

    hasDeviceInformation() {
      const { revision } = this
      return Object.keys(revision.deviceInformation).length
    },

    getDeviceInformation() {
      const { revision } = this
      return revision.deviceInformation
    },

    deviceDetails() {
      const { revision } = this
      if (!revision || !revision.kycDetails) return null

      const details = revision.kycDetails.requestLogData || {}

      return {
        browserType: details.browserType || '-',
        browserName: details.browserName || '-',
        browserVersion: details.browserVersion || '-',
        deviceModel: details.deviceModel,
        userAgent: details.userAgent || '-'
      }
    },

    signDetails() {
      const { revision } = this
      if (!revision || !revision.kycDetails) return null

      const { signData } = revision.kycDetails
      if (!signData) return null

      return {
        ...signData,
        hash: signData.hash || '-',
        time: (signData.time && dateToString(signData.time, true)) || '-'
      }
    },

    kybDetails() {
      const { revision } = this

      if (!revision?.registrationData) return null

      const {
        companyCode,
        ipCountryCode,
        representativeCompanyName
      } = revision.registrationData
      if (!companyCode) return null

      return {
        code: companyCode,
        country: (ipCountryCode && this.$t(ipCountryCode)) || '-',
        name: representativeCompanyName?.trim() || '-'
      }
    },

    identificationData() {
      const { revision } = this
      if (!revision || !revision.registrationData) return null
      const {
        registrationData: {
          status,
          isCrossChecked,
          rejectionReason,
          companyName,
          token
        },
        rules,
        registries,
        fraudCheckResults,
        sanctions,
        videoCallComments
      } = revision

      return {
        status: status || '',
        isCrossChecked: isCrossChecked || false,
        rejectionReason,
        rules,
        registries,
        fraudCheckResults,
        sanctions,
        companyName,
        token,
        videoCallComments: videoCallComments ?? []
      }
    },

    scoresData() {
      const { revision } = this
      if (!revision || !revision.scores || revision.scores.length < 1)
        return null

      return revision.scores.filter((item) => {
        if (item?.name === 'SELFIE_TO_NFC') {
          this.nfcScore = [item]
          return false
        } else {
          return true
        }
      })
    },

    logData() {
      const { revision } = this
      if (!revision) return null
      const { userLogs, actionLogs } = revision

      return {
        boLogs: userLogs,
        kycLogs: actionLogs
      }
    },

    boLogsUi() {
      return this.logData?.boLogs?.map(
        ({ name, date, email, publisher, isOndatoUser, userId }) => {
          return [
            {
              content: isOndatoUser
                ? `${this.$t(
                    `Enum.IdentificationLogs.OndatoSpecialist.${name}`
                  )} (${userId})`
                : this.$t(`Enum.IdentificationLogs.${name}`)
            },
            { content: this.getPublisher(publisher) },
            { content: !isOndatoUser ? email : '' },
            { type: 'time', content: dateToString(date, true) }
          ]
        }
      )
    },

    kycLogsUi() {
      const logs = this.logData?.kycLogs?.filter(
        (item) => !IGNORED_ACTION_LOGS.includes(item.name)
      )
      if (!logs?.length) return null

      return logs.map(({ name, date }) => {
        return [
          { content: this.$t(`Enum.ActionLogs.${name}`) },
          { type: 'time', content: dateToString(date, true) }
        ]
      })
    },

    formData() {
      const { revision } = this
      if (!revision || !revision.kycDetails) return null
      const { formSettingsData, customData, readOnlyData } = revision.kycDetails
      if (!formSettingsData) return null
      return {
        customData,
        readOnlyData,
        settings: formSettingsData.settings
      }
    },

    formSource() {
      const { isFormReady, revision } = this
      if (!isFormReady || !revision || !revision.kycDetails) return null
      const { formSettingsData } = revision.kycDetails
      if (!formSettingsData) return null
      const title = formSettingsData.formType.toLowerCase()
      return `${BaseUrl.form}/forms/${title}/index.html?v=${Date.now()}`
    },

    language() {
      return localStorage.getItem('language') || 'en'
    },

    rawRequestData() {
      const data = this.revision?.kycDetails?.customData?.portalScoped
      if (!data || !Object.keys(data).length) {
        return undefined
      }

      return data
    }
  },

  watch: {
    formData(value, oldValue) {
      if (!value || oldValue) return

      this.$nextTick(() => {
        this.formEvents = new EventController({
          target: this.$refs.form,
          allowedOrigin: BaseUrl.form
        })

        this.formEvents.addListener(eventTypes.formReady, this.formReady)
        this.formEvents.addListener(
          eventTypes.formFileDownload,
          this.formFileDownload
        )
        this.isFormReady = true
      })
    },

    '$i18n.locale'() {
      this.isLoading = true
      this.$router.go()
    }
  },

  async created() {
    const revision = await this.getRevision()
    if (revision) {
      this.revision = revision
      this.renderScreenShareVideo()
    }
    this.hideLogs = this.$store.getters.hideLogs
    if (this.$route.params.export) {
      this.hideButtons = true
      setTimeout(() => this.exportPdf(), 1000)
    }
    this.isIdentificationOpened()
    this.isReady = true
  },

  methods: {
    async handleStatus(status) {
      if (status === MONITORING_CHANGE_STATUS.LOADING) {
        this.isLoading = true
      } else if (status === MONITORING_CHANGE_STATUS.SUCCESS) {
        const revision = await this.getRevision()
        if (revision) {
          this.revision = revision
        }
      }
    },

    passedManualOrAuto(status) {
      return (
        status === REQUEST_STATUS.PASSED_MANUAL ||
        status === REQUEST_STATUS.PASSED_AUTO
      )
    },

    changeMonitoring(revision) {
      const { monitoringData } = this
      this.$set(
        monitoringData,
        'monitoringStatus',
        this.getMonitoringStatus(revision.monitoringData.monitoringStatus)
      )
      this.$set(
        monitoringData,
        'identificationId',
        revision.registrationData.token
      )
      this.$set(monitoringData, 'monitoringId', revision.monitoringData.id)
      this.popUpType = KYC_IDENTIFICATION_POP_UP_TYPES.MONITORING_SETUP
      this.$openPopUp()
    },

    getMonitoringStatus(status) {
      return status === 'IsMonitored'
    },
    async renderScreenShareVideo() {
      const doesScreenVideoExist = this.canExportVideo()
      if (doesScreenVideoExist) {
        try {
          const { file } = await Api.getVideoStream(
            `/identifications/${this.identificationId}/screen-recording-file`
          )
          const windowUrl = window.URL || window.webkitURL
          const fileUrl = windowUrl.createObjectURL(file)
          this.screenShareVideoFile = fileUrl
        } catch (error) {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorUnknownDescription'
          })
        }
      }
    },

    abortPopUp() {
      this.$closePopUp()
      this.popUpType = null
    },

    async clickAddToStoplist() {
      const isDuplicate = await this.isStoplistDuplicate(
        this.identificationData.token
      )
      if (isDuplicate) {
        this.$notify({
          type: notificationType.info,
          title: this.$t('Stoplist.NotificationDuplicate')
        })
      } else {
        this.popUpType = KYC_IDENTIFICATION_POP_UP_TYPES.STOPLIST_CONFIRM
        this.$openPopUp()
      }
    },

    async clickRemoveFromStoplist() {
      try {
        const { StopList } = this.$store.getters
        const selectedStoplist = StopList.find(
          (item) =>
            this.formatReferenceId(item.referenceId) ===
            this.identificationData.token
        )
        if (!selectedStoplist) {
          this.$notify({
            type: notificationType.warning,
            title: 'Stoplist ID not found'
          })
        } else {
          const id = selectedStoplist.id
          await Api.del(`/stop-list/natural-entities/${id}`)
          this.$notify({
            type: notificationType.info,
            title: 'Deleted'
          })
          const { StopList } = this.$store.getters
          const updatedList = StopList.filter(
            (item) =>
              this.formatReferenceId(item.referenceId) !==
              this.identificationData.token
          )
          this.$store.dispatch(actions.updateStopList, updatedList)
        }
      } catch (error) {
        this.$notify({
          type: notificationType.warning,
          title: 'Unexpected error'
        })
      }
    },

    isInStopList(token) {
      const { StopList } = this.$store.getters
      if (!StopList || !StopList.length) {
        return false
      } else {
        return (
          StopList.findIndex(
            (ident) => this.formatReferenceId(ident.referenceId) === token
          ) > -1
        )
      }
    },

    async isStoplistDuplicate(token) {
      const { StopList } = this.$store.getters
      let list = []
      // get StopList from API if is empty
      if (!StopList || !StopList.length) {
        const response = await Api.get('/stop-list/natural-entities')
        this.$store.dispatch(actions.updateStopList, response.data)
        list = response.data
      } else {
        list = StopList
      }
      return (
        list.findIndex(
          (ident) => this.formatReferenceId(ident.referenceId) === token
        ) > -1
      )
    },
    formatReferenceId(id) {
      return id?.replaceAll('-', '')
    },

    async confirmAddToStoplist() {
      this.$openPopUp()
      const payload = {
        identificationId: this.identificationData
          ? this.identificationData.token
          : '',
        firstName: this.documentData.firstName
          ? this.documentData.firstName.value
          : '',
        lastName: this.documentData.lastName
          ? this.documentData.lastName.value
          : '',
        country: this.documentData.country
          ? this.documentData.country.value
          : '',
        personalCode: this.documentData.personCode
          ? this.documentData.personCode.value
          : '',
        dateOfBirth: this.documentData.dateOfBirth
          ? this.documentData.dateOfBirth.value
          : '',
        documentNumber: this.documentData.documentNumber
          ? this.documentData.documentNumber.value
          : ''
      }

      try {
        const { error, id } = await Api.post(
          '/stop-list/natural-entities',
          payload
        )
        if (!error) {
          const { StopList } = this.$store.getters
          const updatedList = [
            ...StopList,
            { referenceId: this.identificationData.token, id }
          ]
          this.$store.dispatch(actions.updateStopList, updatedList)
          this.$notify({
            type: notificationType.info,
            title: 'Added to stoplist'
          })
          this.abortPopUp()
        }
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },

    validationState(status) {
      this.isValid = status
    },

    getPublisher(publisher) {
      switch (publisher) {
        case 'BoApi':
          return 'Backoffice'
        case 'ArbitriumApi':
          return 'Arbitrium'
        default:
          return ''
      }
    },

    canDownloadReport() {
      return (
        !!this.revision &&
        !!this.revision.kycDetails &&
        !!this.revision.kycDetails.formSettingsData &&
        (this.revision.kycDetails.formSettingsData.formType ===
          FORM_TYPES.ONDATO ||
          this.revision.kycDetails.formSettingsData.formType === FORM_TYPES.BIB)
      )
    },

    canExportVideo() {
      const {
        revision: { registrationData, deviceInformation }
      } = this
      const isMobileSDK = !!deviceInformation.sdkVersion
      const isValidCompany =
        VALID_APP_ID_FOR_VIDEO_EXPORT.indexOf(
          registrationData.applicationId.toUpperCase()
        ) > -1

      return isValidCompany && isMobileSDK
    },

    async deleteIdentification() {
      try {
        this.isDeleting = true
        const { error } = await Api.post('/identifications/delete', {
          token: this.identificationData.token
        })

        if (!error) {
          this.revision = null
          this.$router.replace({ name: 'Identifications' })

          this.$notify({
            type: notificationType.info,
            title: 'Deleted'
          })
          return
        }
      } catch (error) {
        // empty
      }

      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })

      this.isDeleting = false
    },

    isIdentificationOpened() {
      const { logData, notifyError } = this
      const { isCrossChecked } = this.identificationData || {}

      if (isCrossChecked || !logData) return

      const kycLogs = deepClone(logData.kycLogs || [])
      const boLogs = deepClone(logData.boLogs || [])

      if (!boLogs.length) return

      const startedOver = kycLogs
        .sort(this.orderByDateDesc)
        .find((item) => item.name === 'SESSION_START_OVER')
      const opened = boLogs
        .sort(this.orderByDate)
        .find(
          (item) =>
            item.name === 'OPENED' &&
            (!startedOver || item.date > startedOver.date)
        )

      if (!opened) return

      const message = `${opened.email} ${dateToString(opened.date, true)}`
      notifyError(
        this.$t('Enum.IdentificationLogs.OPENED'),
        message,
        notificationType.info
      )
    },

    reprocessRegistriesData(documentData) {
      if (this.addReprocessButton) return

      const { identificationData, cloneDocumentData } = this
      const registriesNames = identificationData.registries.map(
        (item) => item.name
      )
      const registriesCheckData = [
        { name: 'LOST_OR_STOLEN', data: ['documentNumber', 'documentType'] },
        {
          name: 'POPULATION',
          data: [
            'firstName',
            'lastName',
            'dateOfBirth',
            'personCode',
            'documentNumber',
            'documentType',
            'dateOfIssue',
            'dateOfExpiration'
          ]
        },
        {
          name: 'DRIVER_LICENSE_DATABASE',
          data: ['firstName', 'lastName', 'personCode', 'documentNumber']
        },
        {
          name: 'SANCTIONS_SCREENING',
          data: ['firstName', 'lastName', 'dateOfBirth']
        },
        { name: 'COMPANY_REPRESENTATIVE', data: ['personCode'] }
      ]

      for (const registry of registriesCheckData) {
        if (!registriesNames.includes(registry.name)) continue

        for (const property of registry.data) {
          const documentValue = documentData[property]
          const propertyData = cloneDocumentData[property]

          let compareValue =
            typeof propertyData === 'object' ? propertyData.value : propertyData

          if (compareValue?.includes('00:00')) {
            compareValue = dateToString(compareValue)
          }

          if (documentValue === compareValue) continue

          this.addReprocessButton = true
          return
        }
      }
    },

    formReady() {
      const { formEvents, formData, identificationId, language } = this
      formEvents.triggerSuccess(eventTypes.formLoad, {
        ...formData,
        language,
        reviewRequired: true,
        editDisabled: true,
        identificationId,
        styles: {
          default: `${BaseUrl.form}/customs/ondato/styles.css`
        }
      })
    },

    formFileDownload({ data }) {
      downloadUrl({
        fileUrl: data.url,
        fileName: data.name
      })
    },

    showHideLogs() {
      if (this.hideLogs) {
        this.hideLogs = false
      } else {
        this.hideLogs = true
      }
      this.$store.dispatch(actions.updateHideLogs, this.hideLogs)
    },

    tabChanged(value) {
      this.tabIndex = value
    },

    enableEdit() {
      this.isEditEnabled = true
      this.cloneDocumentData = deepClone(this.documentData)
    },

    enablePOAEdit() {
      this.isEditPOAEnabled = true
    },

    async reprocessRegistries() {
      try {
        const { data } = await Api.post('/identifications/reprocess', {
          token: this.identificationData.token
        })
        if (data.registries)
          this.$set(this.revision, 'registries', data.registries)
        this.addReprocessButton = false
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },

    addRefreshButton(registries) {
      const registriesStatus = registries.filter(
        (item) => item.status !== statusTypesEnum.succeeded
      )
      if (registriesStatus.length > 0) return true
      else return false
    },

    async exportList(value) {
      switch (value) {
        case 'PDF': {
          await this.exportPdf()
          break
        }
        case 'ASICE': {
          const url = this.revision.kycDetails.esignatureFileUrl
          if (url) window.location.href = url
          break
        }
        case 'VIDEO': {
          const url = this.revision.kycDetails.videoFileUrl
          if (url) window.location.href = url
          break
        }
        case 'SESSION RECORD': {
          const url = this.revision.kycDetails.screenRecordUrl
          if (url) window.location.href = url
          break
        }
        case 'SCREEN RECORD': {
          await this.exportScreenRecord()
          break
        }
        case 'CSV': {
          //
          break
        }
        case 'XLS': {
          //
          break
        }
        case 'ORIGINAL DOCUMENT': {
          const url = this.signDetails.originalDocument
          if (url) window.location.href = url
          break
        }
        case 'SIGNED DOCUMENT': {
          const url = this.signDetails.signedDocument
          if (url) window.location.href = url
          break
        }
        case 'FORM REPORT': {
          await this.downloadFormReport()
          break
        }
        case EXPORT_NAMES[exportMethodTypes.thirdPartyExport]: {
          await this.crmExport()
          break
        }
        default: {
          break
        }
      }
    },

    async downloadFormReport() {
      try {
        const pdfName = `FormReport-[${dateToString(new Date(), true)}].pdf`
        const blob = await Api.getBaseUrlBlob(
          `/identifications/${this.identificationId}/pdf-form?language=${this.language}`
        )
        downloadBlob({ file: blob, fileName: pdfName })
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },

    async exportPdf() {
      this.hideButtons = true

      const files = this.revision?.media.filter(
        (mediaItem) => mediaItem.givenDocumentType === 'PROOF_OF_ADDRESS'
      )

      try {
        const mergedPdf = await PDFDocument.create()
        const pdfName = `OndatoRequests[${dateToString(new Date(), true)}].pdf`
        const blob = await Api.getBaseUrlBlob(
          `/identifications/reports/${this.identificationId}/pdf/${this.language}`
        )
        const cardBuffer = await new Response(blob).arrayBuffer()
        const documents = [{ fileExtension: 'pdf', responseBuffer: cardBuffer }]

        if (files.length) {
          await Promise.all(
            files.map(async (file) => {
              const response = await fetch(file.file)
              const responseBuffer = await new Response(
                await response.blob()
              ).arrayBuffer()

              documents.push({
                fileExtension: file.fileExtension,
                responseBuffer
              })
            })
          )
        }

        for (let document of documents) {
          const { fileExtension, responseBuffer } = document

          if (fileExtension === 'pdf') {
            const pdfDocument = await PDFDocument.load(responseBuffer)
            const copiedPages = await mergedPdf.copyPages(
              pdfDocument,
              pdfDocument.getPageIndices()
            )
            copiedPages.forEach((page) => mergedPdf.addPage(page))
          }
        }

        const pdfBites = await mergedPdf.save()

        const pdf = new Blob([pdfBites], { type: 'application/pdf' })

        downloadBlob({ file: pdf, fileName: pdfName })
        this.hideButtons = false
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
      if (!this.$route.params.export) this.hideButtons = false
    },

    async exportScreenRecord() {
      this.hideButtons = true
      try {
        const { file, fileName } = await Api.getVideoStream(
          `/identifications/${this.identificationId}/screen-recording-file`
        )
        downloadBlob({ file, fileName })
        this.hideButtons = false
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
      if (!this.$route.params.export) this.hideButtons = false
    },

    async crmExport() {
      this.hideButtons = true

      try {
        await Api.post('/third-party-export/customer/export', {
          kycIdentificationId: this.identificationId
        })

        this.notifyError(
          this.$t('Kyc.Export.Success.Title'),
          '',
          notificationType.info
        )
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }

      this.hideButtons = false
    },

    saveChanges(data) {
      const { documentType, documentNumber, ...restData } = data
      const dataToSend = {
        ...restData,
        docType: documentType,
        docNumber: documentNumber
      }
      if (this.addReprocessButton) {
        this.notifyError(
          'RegistriesWarningText',
          '',
          notificationType.warning,
          () => this.updateChanges(dataToSend)
        )
        return
      }

      this.updateChanges(dataToSend)
    },

    async updateChanges(data) {
      const { identificationId } = this
      this.isLoading = true

      try {
        await Api.post('/identifications/update', {
          ...data,
          token: identificationId
        })
        this.reprocessRegistriesData(data)
        this.isEditEnabled = false
      } catch (error) {
        console.error('updateChanges', error)
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
      await this.updateRevision()
      this.isLoading = false
    },

    async savePOAChanges(data) {
      const fields = ['firstName', 'lastName', 'street', 'city', 'parish']
      const hasChangedFields =
        typeof fields.find((field) => {
          return data[field] !== this.proofOfAddressData[field]
        }) !== 'undefined'
      if (!hasChangedFields) {
        this.isEditPOAEnabled = false
        return
      }

      const { identificationId } = this
      this.isLoading = true

      try {
        await Api.post(API.UPDATE_IDENTIFICATION_POA, {
          ...data,
          token: identificationId
        })
        this.isEditPOAEnabled = false
      } catch (error) {
        console.error('updatePOAChanges', error, { data, identificationId })
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }

      await this.updateRevision()
      this.isLoading = false
    },

    notifyError(title, message, type, callback) {
      this.$notify({
        type,
        title,
        message,
        callback
      })
    },

    approveIdentification() {
      if (!this.isValid) {
        this.notifyError('ApproveFailTitle', 'ApproveFail')
        return
      }

      const warningMessage = this.approveWarningMessage()
      if (warningMessage.length > 0) {
        this.notifyError(
          'ApproveWarningText',
          warningMessage,
          notificationType.warning,
          this.approveCallback
        )
      } else {
        this.approveCallback()
      }
    },

    async approveCallback() {
      this.isProcessing = true
      try {
        const { identificationId } = this
        await Api.post('/identifications/confirm', {
          token: identificationId
        })
        const {
          revision: { registrationData }
        } = this
        if (!registrationData.status.includes('Passed')) {
          registrationData.status = 'PassedManual'
        }
        registrationData.rejectionReason = null
        registrationData.isCrossChecked = true
        this.notifyError('ApproveSuccess', '', notificationType.info)
      } catch (error) {
        this.notifyError('ErrorUnknownTitle', 'ErrorUnknownDescription')
      }
      this.isProcessing = false
    },

    async updateRevision() {
      this.revision = await this.getRevision()
    },

    isInExceptionList(item) {
      return (
        item.failReason &&
        exceptionReasons.includes(item.failReason) &&
        exceptionRules.includes(item.name)
      )
    },

    approveWarningMessage() {
      const array = []
      this.identificationData.rules.forEach((item) => {
        if (item.status !== 'SUCCESS')
          array.push(
            `${
              this.isInExceptionList(item)
                ? `RULE_REASON_${item.failReason}_${item.name}`
                : `RULE_REASON_${item.failReason}`
            }`
          )
      })
      this.identificationData.registries.forEach((item) => {
        if (item.status === 'UNAVAILABLE') array.push(`${item.name}`)
        else if (item.status === 'FAIL') array.push(`${item.name}_FAIL`)
      })
      return array
    },

    async rejectionReasonSelected(value) {
      this.isProcessing = true
      try {
        const { identificationId } = this
        await Api.post('/identifications/reject', {
          token: identificationId,
          reason: value
        })
        const {
          revision: { registrationData }
        } = this
        registrationData.status = 'Rejected'
        registrationData.rejectionReason = value
        registrationData.isCrossChecked = true
        this.$notify({
          type: notificationType.info,
          title: 'RejectSuccess'
        })
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
      this.isProcessing = false
    },

    havePersonCodeRule() {
      const ruleName = 'PERSONAL_NUMBER_MATCH'
      const array = []
      this.identificationData.rules.forEach((item) => {
        if (item.name === ruleName) array.push('exist')
      })
      if (array.length > 0) return true
      else return false
    },

    async getStopList() {
      try {
        const response = await Api.get(`/stop-list/natural-entities`)
        this.$store.dispatch(actions.updateStopList, response.data)
        return response.data
      } catch (error) {
        // empty
      }
    },

    async getRevision() {
      const { identificationId } = this
      let revision
      try {
        revision = await Api.get(`/identifications/${identificationId}/details`)
      } catch (error) {
        // empty
      }
      return revision
    },

    orderByDate({ date: date1 }, { date: date2 }) {
      return date1 > date2 ? 1 : date1 < date2 ? -1 : 0
    },

    orderByDateDesc({ date: date1 }, { date: date2 }) {
      return date1 < date2 ? 1 : date1 > date2 ? -1 : 0
    },

    mapFraudCheckStatus(status) {
      if (status === 'UNKNOWN') {
        return 'UNAVAILABLE'
      } else {
        return status
      }
    },

    showIpListDetails() {
      this.popUpType = KYC_IDENTIFICATION_POP_UP_TYPES.IP_LIST
      this.$openPopUp()
    },

    showSeonDetails(type, fileId) {
      this.popUpType = type
      this.seonFileId = fileId
      this.$openPopUp()
    }
  }
}
</script>

<style scoped>
.red-block {
  background-color: var(--brand);
  cursor: unset;
  border-radius: 0.75rem;
  color: var(--white);
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: center;
}

.show-button,
.hide-button {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.show-button::after {
  content: '';
  right: 1rem;
  top: -0.5rem;
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  border-left: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  transform: rotate(-45deg);
}

.hide-button::after {
  content: '';
  top: 0;
  right: 1rem;
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  border-right: 1px solid var(--gray);
  border-top: 1px solid var(--gray);
  transform: rotate(-45deg);
  transform-origin: center;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.vertical-center {
  display: flex;
}

.vertical-center .ui-checkbox {
  margin-top: auto;
  margin-bottom: auto;
}

.display-flex-wrap > * {
  flex-basis: 45%;
}

.popup-text {
  margin-bottom: 15px;
}

.header-box {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.header-box div > .ui-dropdown {
  height: 3.5rem;
}

.header-box > .buttons-group {
  display: flex;
  flex-flow: wrap row;
}

.header-box > .buttons-group > * {
  margin-left: 1rem;
  height: 3.5rem;
  margin-bottom: 0.25rem;
}

.loader-absolute {
  position: fixed;
  width: calc(100% - 170px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  padding-top: 2.5rem;
  margin-top: -2.5rem;
  margin-right: 30px;
}

.loader-absolute.white-bg {
  background-color: #f2f3f8;
}

.hide-buttons {
  position: relative;
}

.display-flex-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.form-data iframe {
  height: 500px;
  width: 100%;
  border: none;
}

.wide-content .details-box h5 {
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

@media (max-width: 1024px) {
  .loader-absolute {
    width: calc(100% - 150px);
  }
}

@media (max-width: 568px) {
  .loader-absolute {
    margin-left: -15px;
    width: calc(100% - 120px);
  }
}
</style>

<style>
.identification-details-page .full-size {
  padding-bottom: 2.2rem;
}

.identification-details-page .full-size > .m-portlet {
  height: 100%;
  margin-bottom: 0;
}

.logs-export-block .m-portlet__head {
  cursor: pointer;
}

.hide-buttons button,
.hide-buttons .play-button::before,
.hide-buttons .play-button::after {
  display: none;
}

.error-text .details-value .dropdown-readonly {
  color: var(--danger);
}

@media (max-width: 1199px) {
  .identification-details-page .col-xl-4,
  .identification-details-page .col-xl-8 {
    margin-bottom: 2.2rem;
  }
}

@media (max-width: 568px) {
  .header-box > .buttons-group > *,
  .header-box button.ui-button {
    width: 100%;
  }

  .header-box .ui-button .btn-content {
    margin: auto;
  }

  .col-xl-4,
  .col-xl-8 {
    padding-left: 0;
    padding-right: 0;
  }

  .box-with-header .sticky-header,
  .box-with-header .sticky-header > * {
    justify-content: center;
    margin-left: 0;
  }

  .m-portlet__head-tools .show-button,
  .m-portlet__head-tools .hide-button {
    position: absolute;
    padding: 0;
    right: 0;
    top: 1.75rem;
  }

  .m-portlet__head-tools .hide-button::after {
    bottom: -1.25rem;
  }
}

.identification-details-page .rules-box .details-item + .details-item {
  border-top: none;
}

.identification-details-page .rules-box span {
  padding-top: 0;
  padding-bottom: 0;
}

.identification-details-page .rules-box .status-item {
  padding-left: 2.2rem;
  padding-right: 2.2rem;
}

.identification-details-page .rules-box .status-item h5 {
  font-size: 12px;
  font-family: 'work_sanslight', sans-serif;
  margin-bottom: auto;
}
</style>
